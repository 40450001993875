@tailwind base;

@layer base {
  :root {
    /* Black */
    --ax-color-black: 0 0 0; /* #000000 */

    /* White */
    --ax-color-white: 255 255 255; /* #FFFFFF */

    /* Gray */
    --ax-color-gray-0: 246 248 250; /* #F6F8FA */
    --ax-color-gray-1: 238 243 247; /* #EEF3F7 */
    --ax-color-gray-2: 222 229 236; /* #DEE5EC */
    --ax-color-gray-3: 222 226 230; /* #DEE2E6 */
    --ax-color-gray-4: 206 212 218; /* #CED4DA */
    --ax-color-gray-5: 197 208 218; /* #C5D0DA */
    --ax-color-gray-6: 168 180 191; /* #A8B4BF */
    --ax-color-gray-7: 110 124 137; /* #6E7C89 */
    --ax-color-gray-8: 52 58 64; /* #343A40 */
    --ax-color-gray-9: 39 49 59; /* #27313B */

    /* Red */
    --ax-color-red-0: 255 245 245; /* #FFF5F5 */
    --ax-color-red-1: 255 207 207; /* #FFCFCF */
    --ax-color-red-2: 255 170 186; /* #FFAABA */
    --ax-color-red-3: 255 85 117; /* #FF5575 */
    --ax-color-red-4: 255 128 151; /* #FF8097 */
    --ax-color-red-5: 255 85 117; /* #FF5575 */
    --ax-color-red-6: 255 43 82; /* #FF2B52 */
    --ax-color-red-7: 240 62 62; /* #F03E3E */
    --ax-color-red-8: 204 34 66; /* #CC2242 */
    --ax-color-red-9: 201 42 42; /* #C92A2A */

    /* Blue */
    --ax-color-blue-0: 232 243 252; /* #E8F3FC */
    --ax-color-blue-1: 195 222 247; /* #C3DEF7 */
    --ax-color-blue-2: 175 211 245; /* #AFD3F5 */
    --ax-color-blue-3: 96 168 234; /* #60A8EA */
    --ax-color-blue-4: 125 179 230; /* #7DB3E6 */
    --ax-color-blue-5: 91 163 230; /* #5BA3E6 */
    --ax-color-blue-6: 56 146 229; /* #3892E5 */
    --ax-color-blue-7: 28 126 214; /* #1C7ED6 */
    --ax-color-blue-8: 45 117 183; /* #2D75B7 */
    --ax-color-blue-9: 0 91 176; /* #005BB0 */

    /* Green */
    --ax-color-green-0: 235 251 238; /* #EBFBEE */
    --ax-color-green-1: 198 234 216; /* #C6EAD8 */
    --ax-color-green-2: 163 211 187; /* #A3D3BB */
    --ax-color-green-3: 140 233 154; /* #8CE99A */
    --ax-color-green-4: 129 202 165; /* #81CAA5 */
    --ax-color-green-5: 97 193 145; /* #61C191 */
    --ax-color-green-6: 64 184 124; /* #40B87C */
    --ax-color-green-7: 55 178 77; /* #37B24D */
    --ax-color-green-8: 47 158 68; /* #2F9E44 */
    --ax-color-green-9: 43 138 62; /* #2B8A3E */

    /* Orange */
    --ax-color-orange-0: 255 244 230; /* #FFF4E6 */
    --ax-color-orange-1: 255 232 204; /* #FFE8CC */
    --ax-color-orange-2: 234 200 170; /* #EAC8AA */
    --ax-color-orange-3: 245 177 117; /* #F5B175 */
    --ax-color-orange-4: 238 187 141; /* #EEBB8D */
    --ax-color-orange-5: 240 172 112; /* #F0AC70 */
    --ax-color-orange-6: 243 158 82; /* #F39E52 */
    --ax-color-orange-7: 230 150 78; /* #E6964E */
    --ax-color-orange-8: 219 142 74; /* #DB8E4A */
    --ax-color-orange-9: 197 128 67; /* #C58043 */

    /* Cyan */
    --ax-color-cyan-0: 227 250 252; /* #E3FAFC */
    --ax-color-cyan-1: 199 241 244; /* #C7F1F4 */
    --ax-color-cyan-2: 179 237 240; /* #B3EDF0 */
    --ax-color-cyan-3: 102 220 225; /* #66DCE1 */
    --ax-color-cyan-4: 102 220 225; /* #66DCE1 */
    --ax-color-cyan-5: 51 208 215; /* #33D0D7 */
    --ax-color-cyan-6: 0 196 205; /* #00C4CD */
    --ax-color-cyan-7: 16 152 173; /* #1098AD */
    --ax-color-cyan-8: 0 157 165; /* #009DA5 */
    --ax-color-cyan-9: 11 114 133; /* #0B7285 */

    /* Yellow */
    --ax-color-yellow-0: 255 249 219; /* #FFF9DB */
    --ax-color-yellow-1: 255 233 197; /* #FFE9C5 */
    --ax-color-yellow-2: 235 219 155; /* #EBDB9B */
    --ax-color-yellow-3: 247 216 88; /* #F7D850 */
    --ax-color-yellow-4: 239 216 120; /* #EFD878 */
    --ax-color-yellow-5: 242 211 83; /* #F2D353 */
    --ax-color-yellow-6: 245 206 46; /* #F5CE2E */
    --ax-color-yellow-7: 221 185 41; /* #DDB929 */
    --ax-color-yellow-8: 196 165 37; /* #C4A525 */
    --ax-color-yellow-9: 180 149 33; /* #B49521 */

    /* Lime */
    --ax-color-lime-0: 244 252 227; /* #F4FCE3 */
    --ax-color-lime-1: 233 250 200; /* #E9FAC8 */
    --ax-color-lime-2: 195 215 158; /* #C3D79E */
    --ax-color-lime-3: 166 208 93; /* #A6D05D */
    --ax-color-lime-4: 178 210 124; /* #B2D27C */
    --ax-color-lime-5: 161 203 88; /* #A1CB58 */
    --ax-color-lime-6: 144 196 53; /* #90C435 */
    --ax-color-lime-7: 130 176 47; /* #82B02F */
    --ax-color-lime-8: 115 156 42; /* #739C2A */
    --ax-color-lime-9: 92 148 13; /* #5C940D */

    /* Purple */
    --ax-color-purple-1: 237 224 244; /* #EDE0F4 */
    --ax-color-purple-2: 223 199 235; /* #DFC7EB */
    --ax-color-purple-3: 202 163 221; /* #CAA3DD */
    --ax-color-purple-4: 178 123 204; /* #B27BCC */
    --ax-color-purple-5: 155 85 178; /* #9B55B2 */
    --ax-color-purple-6: 132 48 152; /* #843098 */
    --ax-color-purple-7: 109 12 126; /* #6D0C7E */
    --ax-color-purple-8: 86 0 100; /* #560064 */

    /* Status */
    --ax-color-status-red: 255 207 207; /* #FFCFCF */
    --ax-color-status-yellow: 255 233 197; /* #FFE9C5 */
    --ax-color-status-green: 198 234 216; /* #C6EAD8 */
    --ax-color-status-mint: 199 241 244; /* #C7F1F4 */
    --ax-color-status-blue: 195 222 247; /* #C3DEF7 */
    --ax-color-status-purple: 235 198 248; /* #EBC6F8 */
    --ax-color-status-pink: 255 225 245; /* #FFE1F5 */
    --ax-color-status-grey: 212 216 220; /* #D4D8DC */
    --ax-color-success: 64 184 124; /* #40B87C */
    --ax-color-failure: 255 95 95; /* #FF5F5F */
    --ax-color-warning: 255 182 61; /* #FFB63D */
    --ax-color-info: 56 146 229; /* #3892E5 */
    --ax-color-link: 56 146 229; /* #3892E5 */

    /* Text */
    --ax-color-text-primary: 39 49 59; /* #27313B */
    --ax-color-text-secondary: 110 124 137; /* #6E7C89 */
    --ax-color-text-placeholder: 168 180 191; /* #A8B4BF */
    --ax-color-text-disabled: 168 180 191; /* #A8B4BF */

    /* Background */
    --ax-color-bg-primary: 246 248 250; /* #F6F8FA */
    --ax-color-bg-secondary: 255 255 255; /* #FFFFFF */
    --ax-color-bg-muted: 238 243 247; /* #EEF3F7 */
    --ax-color-bg-disabled: 246 248 250; /* #F6F8FA */

    /* Border */
    --ax-color-border-base: 222 229 236; /* #DEE5EC */
    --ax-color-border-divide: 238 243 247; /* #EEF3F7 */
    --ax-color-border-muted: 197 208 218; /* #C5D0DA */
    --ax-color-border-disabled: 222 229 236; /* #DEE5EC */

    /* Brand */
    --ax-color-brand: 0 196 206; /* #00C4CE */
    --ax-color-brand-hover: 102 220 225; /* #66DCE1 */
    --ax-color-brand-active: 0 157 165; /* #009DA5 */
  }
}
