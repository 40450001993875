/* Base */
@import url('tailwindcss/base');
@import url('./base/base.css');
@import url('@mantine/core/styles.css');
@import url('@mantine/notifications/styles.css');
@import url('@mantine/dates/styles.css');
@import url('@any-ui-react/core/dist/style.css');

/* Variable */
@import url('./variables/variables.css');

/* Vendors */
@import url('./vendors/vendors.css');

/* Components */
@import url('tailwindcss/components');
@import url('./components/components.css');
@import url('../../../../libs/function-chat/src/styles/components.css');
@import url('../../../../libs/feature/crm/src/styles/components.css');
@import url('../../../../libs/function/workflow/src/styles/components.css');

/* Utilities */
@import url('tailwindcss/utilities');
