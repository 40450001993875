/* stylelint-disable selector-class-pattern */
.list-numbered {
  @apply flex flex-col gap-y-4 text-sm leading-8;

  counter-reset: line;

  > li {
    @apply text-gray-9 relative inline-flex text-sm leading-8;

    &::before {
      /* stylelint-disable-next-line max-line-length */
      @apply text-3xs bg-gray-9 mr-1 inline-flex h-4 w-4 shrink-0 items-center justify-center rounded-full text-center leading-relaxed text-white;

      counter-increment: line;
      content: counter(line);
    }
  }
}

.list-numbered_lg {
  > li {
    @apply leading-11 text-base font-semibold;

    &::before {
      @apply h-6 w-6 text-sm;
    }
  }
}

.mobile-preview {
  @apply relative aspect-[9/16] w-[400px] rounded-[1.125rem] bg-white p-3 sm:min-h-0;

  &__background {
    @apply bg-gray-0 relative flex h-full flex-col gap-y-6 overflow-auto px-4 pb-6 pt-10;
  }
}

.dialog-template {
  @apply w-[30rem] space-y-6 p-6 lg:p-8;
}

.crm-select-button {
  @apply border-gray-2 focus:border-blue-6 active:border-blue-6 flex h-8 w-full items-center justify-between rounded border px-3 outline-none transition-all focus:border focus-visible:border;
}

.crm-builder {
  &__rule-card {
    &[data-compact='false'] {
      .any-select__control,
      .mantine-Input-wrapper {
        @apply mb-0;
      }

      .mantine-InputWrapper-error {
        @apply text-3xs absolute;
      }
    }
  }
}

.crm-line-message-preview {
  @apply border-gray-1 divide-gray-1 divide-y divide-solid rounded-lg border;

  &__list-item {
    @apply grid h-16 w-full grid-cols-2 items-center gap-x-4 px-4;
  }
}
