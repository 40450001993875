/* stylelint-disable selector-class-pattern */
@layer components {
  .wf-icon-border {
    @apply flex h-10 w-10 items-center justify-center rounded border;
  }

  .wf-connector-arrow {
    &__body {
      @apply bg-gray-7 h-[3.65rem] w-0.5;
    }

    &__small-body {
      @apply bg-gray-5 h-[1.938rem] w-0.5;
    }

    &__head {
      @apply border-t-gray-7 h-0 w-0 border-x-[0.3rem] border-t-[0.3rem] border-x-transparent;
    }

    &__upside-down {
      @apply border-b-gray-5 -mt-[0.2rem] h-0 w-0 border-x-[0.3rem] border-b-[0.3rem] border-x-transparent;
    }
  }

  .wf-card-connector {
    @apply border-gray-2 absolute top-0 -mt-4 ml-8 block h-4 w-0 border;
  }

  .wf-select-button {
    @apply border-gray-2 focus:border-blue-6 active:border-blue-6 flex h-8 w-full items-center justify-between rounded border px-3 outline-none transition-all focus:border focus-visible:border;
  }

  .wf-builder {
    &__action-row,
    &__rule-card {
      .mantine-Input-wrapper {
        @apply mb-0;
      }

      .mantine-InputWrapper-error {
        @apply text-3xs absolute;
      }
    }
  }
}
