@layer components {
  .card {
    @apply shadow-card rounded bg-white;
  }

  .modal-sm {
    @apply sm:w-3/5 md:w-2/5 lg:w-5/12 xl:w-1/3;
  }

  .modal-md {
    @apply sm:w-5/6 md:w-4/5 lg:w-6/12 xl:w-1/2;
  }

  .modal-lg {
    @apply sm:w-5/6 md:w-4/5 lg:w-8/12 xl:w-3/4;
  }

  .form-fieldset {
    @apply mb-6 grid grid-cols-1 gap-4 lg:grid-cols-2 lg:gap-x-6;
  }
}
