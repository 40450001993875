@layer components {
  .anc-page-wrap {
    @apply lg:px-6 lg:pt-4;
  }

  .anc-page-clamped {
    @apply w-full max-w-5xl lg:mx-auto;
  }

  .title-primary {
    @apply text-gray-9 text-lg font-semibold;
  }

  .hover-transition {
    @apply transition duration-150 ease-in-out;
  }

  .break-anywhere {
    overflow-wrap: anywhere;
    @apply break-all;
  }

  .text-skeleton {
    @apply bg-gray-2 h-2 rounded-lg;
  }

  .anc-table-link {
    @apply group-hover:text-link hover:text-link line-clamp-2 whitespace-normal text-sm hover:underline;
  }

  .animate-text-skeleton {
    @apply text-skeleton animate-pulse;
  }

  .anychat-editor {
    /* stylelint-disable-next-line max-line-length */
    @apply text-gray-9 flex max-h-[13rem] min-h-[3.75rem] w-full cursor-text resize-none flex-col rounded border-0 text-xs leading-[1.125rem] focus:border-none focus:outline-none focus:ring-transparent;

    /* stylelint-disable-next-line selector-class-pattern */
    .DraftEditor-root {
      @apply grow overflow-y-auto p-3;
    }

    /* stylelint-disable-next-line selector-class-pattern */
    .public-DraftEditorPlaceholder-root {
      @apply left-0 top-0 p-3;
    }

    /* stylelint-disable-next-line selector-class-pattern */
    .public-DraftEditor-content {
      @apply break-anywhere !important;
    }
  }

  .anychat-editor-tools-btn {
    /* stylelint-disable-next-line max-line-length */
    @apply hover:border-gray-5 active:bg-gray-0 active:border-gray-5 focus-visible:bg-gray-0 focus-visible:border-gray-5 rounded border border-transparent p-px focus:outline-none;

    svg {
      @apply h-5 w-5;
    }
  }

  .scrollbar-style-none {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */

    &::-webkit-scrollbar {
      display: none; /* Safari and Chrome */
    }
  }

  /*
    * Fix overflow scroll ignoring margin/padding.
    * @see https://chenhuijing.com/blog/flexbox-and-padding/
    * @see https://itnext.io/horizontal-overflow-with-flexbox-css-64f530495303
  */
  .scrollfix::before,
  .scrollfix::after {
    content: '';
    display: inline-block;
    flex: 0 0 auto;
    width: 1px;
    margin-left: -1px;
  }

  .snap-slider-center {
    /* stylelint-disable-next-line max-line-length */
    @apply scrollbar-style-none scrollfix relative flex snap-x snap-mandatory flex-nowrap overflow-x-auto overscroll-x-contain [-ms-scroll-snap-type:x_mandatory];

    .snap-slides {
      /* stylelint-disable-next-line max-line-length */
      @apply mx-1 flex w-[calc(100%-theme('spacing.6')*2)] max-w-none snap-center snap-always flex-col items-center justify-center [flex:0_0_auto] first:ml-0.5 last:mr-3 only:mx-auto;

      /* stylelint-disable-next-line selector-class-pattern */
      &.snap-slides_align-right {
        @apply only:mr-0;
      }
    }
  }

  .anc-customer-tag-dialog {
    @apply flex min-h-[14.5rem] flex-col rounded bg-white;
  }

  .anc-dialog-template {
    @apply w-[30rem] space-y-6 p-6 lg:p-8;
  }

  .anc-staff-tag {
    @apply bg-gray-5 text-2xs rounded px-2 py-1 leading-none;
  }

  .anc-form-block {
    @apply flex gap-y-2;
  }

  .anc-image {
    @apply bg-gray-2 overflow-hidden rounded;

    &__fallback {
      @apply bg-gray-2 grid h-full w-full place-items-center rounded;
    }
  }

  .anc-tag-select {
    .any-select {
      &__control {
        @apply max-h-24 overflow-auto;
      }

      &__multi-value {
        @apply bg-gray-1 overflow-hidden rounded;

        &__label {
          @apply text-gray-9 text-2xs rounded px-1 py-0.5;
        }
      }
    }
  }
}
